import { FC, useEffect, useState } from "react";
import CustomDialog from "../../../../components/mui/dialog";
import { Box, Grid, FormControl, InputLabel, MenuItem } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  addLocation as addCategory,
  orderCheck,
  getLocationCategoryById,
  upDateLoation as updateCategory,
} from "../../../../services/configurations/favourite-location";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { useLanguage } from "../../../../components/language-provider";
//import ErrorMessage from "../../../../components/error/errorStatement";
import { findNameByLanguage } from "../../../../utils";
import { styled } from "@mui/material";
import "./style.scss";
import { locationCategorySchema } from "../../../../utils";
import * as yup from "yup";
import { props } from "../../../../interfaces/configuration/favourite-location";
import Loader from "../../../../components/mui/loader/Loader";
import { StyledTextField, StyledSelect } from "../../../user/manage";
import { debounce } from "../../../../utils";
import EmojiPicker from "../../../../components/emoji-picker";


// const CustomTimePicker: React.FC<any> = (props) => {
//   return (
//     <TimePicker
//       {...props}
//       renderInput={(params:any) => (
//         <TextField {...params} readOnly />
//       )}
//     />
//   );
// };

const CustomTimePicker = styled(TimePicker)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#919eab33",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-input": {
      height: "2em",
    },
  },
}));

interface IError {
  category?: string;
  order?: string;
  reminderDay?: string;
  reminderTime?: string;
  autoOfferDay?: string;
  autoOfferTime?: string;
  emoji ?: string
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Manage: FC<props> = ({
  isOpen,
  type,
  isDisabled,
  onClose,
  data,
  fetchData,
  loader,
  location,
}) => {
  isDisabled = type === "view" ? true : false;
  // const [state, setState] = useState<IState>({
  //   info: {
  //     name: [{ value: "" }],
  //     order: "",
  //     id: "",
  //   },emoji
  //   orderError: false,
  //   availableOrder: false,
  //   orderCheck: false,
  //   initialOrder: "",
  // });

  const [categoryStates, setCategoryStates] = useState({
    category: "",
    order: "",
    reminderDay: "",
    reminderTime: null,
    autoOfferDay: '',
    autoOfferTime: null,
  });

  const [order, setOrder] = useState("");

  const [error, setError] = useState<IError>({
    category: "",
    order: "",
    reminderDay: "",
    reminderTime: "",
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [emoji,setEmoji] = useState('')
  const { language } = useLanguage();
  /* eslint-disable */
  useEffect(() => {
    if (type === "new") {
      setCategoryStates((prev) => {
        return {
          ...prev,
          category: "",
          order: "",
          reminderDay: "",
          reminderTime: null,
          autoOfferDay: '',
          autoOfferTime: null
        };
      });
      setOrder("")
      setEmoji("")
    }
    if (type === "edit") {
      setLoading(true);
      getLocationCategoryById(data.id)
        .then((res: any) => {
          setCategoryStates((prev: any) => {
            return {
              ...prev,
              category: findNameByLanguage(language, res.data.locationName),
              order: res.data.order,
              reminderDay: res.data.reminderDay,
              reminderTime: dayjs(res.data.reminderTime),
              autoOfferDay:res.data?.autoOfferDay || '',
              autoOfferTime: res.data?.autoOfferTime ? dayjs(res.data.autoOfferTime) : null,
              emoji : res.data.emoji
            };
          });
          setOrder(res.data.order)
          setEmoji(res.data.emoji)
          setLoading(false);
        })
        .catch(() =>
          enqueueSnackbar("Couldn't fetch data", { variant: "error" })
        );
    }
  }, [type, data]);
  /* eslint-enable */
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (error) {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }
    setCategoryStates((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setError((prev) => {
      return {
        ...prev,
        category: "",
        order: "",
        reminderDay: "",
        reminderTime: "",
        autoOfferDay: "",
        autoOfferTime:""
      };
    });
    setError({})
    setEmoji("")
    setOrder("")
    onClose();
  };

  const handleOrderChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOrder(event.target.value);
    debouncedSearch(event.target.value);
  };
  const handleConfirm = () => {
    const payload = {
      locationName: [
        {
          lang: language,
          value: categoryStates.category,
        },
      ],
      order: categoryStates.order,
      reminderDay: categoryStates.reminderDay,
      reminderTime:
        categoryStates.reminderTime !== null
          ? (categoryStates.reminderTime as Date).toISOString()
          : categoryStates.reminderTime,
      autoOfferDay: categoryStates.autoOfferDay,
      autoOfferTime: categoryStates.autoOfferTime !== null
        ? (categoryStates.autoOfferTime as Date).toISOString()
        : categoryStates.autoOfferTime,
      emoji : emoji
    };
    if (type === "new") {
      locationCategorySchema
        .validate({...categoryStates,emoji:emoji}, { abortEarly: false })
        .then(() => {
          addCategory(payload)
            .then(() => {
              enqueueSnackbar("Category added successfully", {
                variant: "success",
              });
              handleClose();
              fetchData();
            })
            .catch(() =>
              enqueueSnackbar("Unexpected error occured", { variant: "error" })
            );
        })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          setError(errors);
        });
    }
    if (type === "edit") {
      const newPayload = { ...payload, id: data.id };
      locationCategorySchema
        .validate({...categoryStates,emoji:emoji}, { abortEarly: false })
        .then(() => {
              updateCategory(newPayload)
                .then(() => {
                  enqueueSnackbar("Category updated successfully", {
                    variant: "success",
                  });
                  handleClose();
                  fetchData();
                })
                .catch(() =>
                  enqueueSnackbar("Unexpected error occured", {
                    variant: "error",
                  })
                );
            })
        .catch((error) => {
          const errors: { [key: string]: string } = {};
          error.inner.forEach((fieldError: yup.ValidationError) => {
            errors[fieldError.path!] = fieldError.message;
          });
          setError(errors);
        });
    }
  };
  function checkOrderAvailability(order: string | number) {
    if (order) {
      let query = {};

      if (type === "new") {
        query = {
          order: order,
        };
      }

      if (type === "edit") {
        query = {
          order: order,
          id: data.id,
        };
      }
      orderCheck(query)
        .then(() => {
          setCategoryStates((prev: any) => {
            return {
              ...prev,
              order,
            };
          });
          setError((prev) => {
            return {
              ...prev,
              order: "Order available",
            };
          });
        })
        .catch(() => {
          setError((prev) => {
            return {
              ...prev,
              order: "Order already exists",
            };
          });
        });
    } else {
      setError((prev) => {
        return {
          ...prev,
          order: "",
        };
      });
    }
  }

  const debouncedSearch = debounce(checkOrderAvailability, 100);

  const onEmojiClick = (emojiObject:any,event:any) => {
    const emoji = emojiObject.emoji; 
    setEmoji(emoji); 
  };

  const handleEmojiChange = (event:any) => {
     if(event.target.value === ""){
      setEmoji("")
     }
  }


  return (
    <>
      {loading && isOpen ? (
        <Loader />
      ) : (
        <CustomDialog
          title={type === "edit" ? "Edit category" : "Add new category"}
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleConfirm}
          disabled={isDisabled}
        >
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} className="grid">
                <StyledTextField
                  label="Category Name"
                  name="category"
                  value={categoryStates.category}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                {error && error.category && (
                  <small className="error-message">{error.category}</small>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="grid">
                <StyledTextField
                  label="Order"
                  name="order"
                  value={order}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/; // regex to match only digits
                    if (e.target.value === "" || re.test(e.target.value)) {
                      handleOrderChange(e);
                    }
                  }}
                  disabled={isDisabled}
                />
                {error && error.order && (
                  <small
                    className={
                      error.order === "Order available"
                        ? "success-message"
                        : "error-message"
                    }
                  >
                    {error.order}
                  </small>
                )}
              </Grid>

              <Grid item xs={12} md={6} className="grid">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Offer carpool's reminder day
                  </InputLabel>
                  <StyledSelect
                    size="small"
                    value={categoryStates.reminderDay}
                    name="reminderDay"
                    label="Offer carpool's reminder day"
                    onChange={handleChange}
                  >
                    {days.map((day: string, key: number) => (
                      <MenuItem key={key} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                {error && error.reminderDay && (
                  <small className="error-message">{error.reminderDay}</small>
                )}
              </Grid>

              <Grid item xs={12} md={6} className="grid">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomTimePicker
                    ampm={false}
                    label="Offer carpool's reminder time"
                    value={categoryStates.reminderTime}
                    onChange={(newValue: any) => {
                      if (error.reminderTime) {
                        setError((prev) => {
                          return {
                            ...prev,
                            reminderTime: "",
                          };
                        });
                      }
                      setCategoryStates((prev) => {
                        return {
                          ...prev,
                          reminderTime: newValue,
                        };
                      });
                    }}
                  />
                </LocalizationProvider>
                {error && error.reminderTime && (
                  <small className="error-message">{error.reminderTime}</small>
                )}
              </Grid>

                {/* Auto offer day and time */}
                <Grid item xs={12} md={6} className="grid">
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Auto offer day
                    </InputLabel>
                    <StyledSelect
                      size="small"
                      value={categoryStates.autoOfferDay}
                      name="autoOfferDay"
                      label="Auto offer day"
                      onChange={handleChange}
                    >
                      {days.map((day: string, key: number) => (
                        <MenuItem key={key} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                  {error && error.autoOfferDay && (
                    <small className="error-message">{error.autoOfferDay}</small>
                  )}
                </Grid>

                <Grid item xs={12} md={6} className="grid">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomTimePicker
                      ampm={false}
                      label="Auto offer time"
                      value={categoryStates.autoOfferTime}
                      onChange={(newValue: any) => {
                        if (error.autoOfferTime) {
                          setError((prev) => {
                            return {
                              ...prev,
                              autoOfferTime: "",
                            };
                          });
                        }
                        setCategoryStates((prev) => {
                          return {
                            ...prev,
                            autoOfferTime: newValue,
                          };
                        });
                      }}
                    />
                  </LocalizationProvider>
                  {error && error.autoOfferTime && (
                    <small className="error-message">{error.autoOfferTime}</small>
                  )}
                </Grid>

                {/* End of auto offer day and time */}

              <Grid item xs={12} md={6} className="grid">
                <EmojiPicker
                  inputStr={emoji}
                  onEmojiClick={onEmojiClick}
                  handleEmojiChange={handleEmojiChange}
                />

                {error && error.emoji && (
                  <small className="error-message">
                    {emoji ? "" : error.emoji}
                  </small>
                )}
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};
Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;
